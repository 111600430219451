import { render, staticRenderFns } from "./LabelDefault.vue?vue&type=template&id=4a13b598&scoped=true&"
import script from "./LabelDefault.vue?vue&type=script&lang=js&"
export * from "./LabelDefault.vue?vue&type=script&lang=js&"
import style0 from "./LabelDefault.vue?vue&type=style&index=0&id=4a13b598&scoped=true&lang=scss&"
import style1 from "./LabelDefault.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a13b598",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard})
